import { useState } from "react";
import sellerService from "../services/seller.service";

export default function SellerDetails({
  data,
  clearLoading,
  setLoading,
  dispatch,
  clearDrawer,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [commission, setCommission] = useState(data.cm);

  async function ApproveDetails(action) {
    dispatch(setLoading());
    try {
      await sellerService.approveSeller({
        id: data._id,
        action: action,
      });
      dispatch(clearLoading());
      dispatch(clearDrawer());
    } catch (error) {
      dispatch(clearLoading());
    }
  }

  async function UpdateSellerDetails(cm) {
    dispatch(setLoading());
    try {
      await sellerService.updateSeller({
        id: data._id,
        cm: cm,
      });
      dispatch(clearLoading());
      dispatch(clearDrawer());
    } catch (error) {
      dispatch(clearLoading());
    }
  }

  return (
    <div className="container p-10">
      <div className="text-purple-500 text-md mt-3 mb-6 font-light uppercase">
        Personal Details
      </div>
      <div>
        <ul>
          <li>Name : {data.name}</li>
          <li>Email : {data.email}</li>
          <li>Phone : {data.phoneNumber}</li>
          <li className="flex items-center gap-2">
            Commission :
            {isEditing ? (
              <input
                type="number"
                value={commission}
                onChange={(e) => setCommission(e.target.value)}
                className="border p-1 w-16 rounded"
              />
            ) : (
              <span>{commission}%</span>
            )}
            {isEditing ? (
              <button
                onClick={() => {
                  UpdateSellerDetails(commission);
                  setIsEditing(false);
                }}
                className="px-3 py-1 text-sm text-white bg-blue-600 rounded hover:bg-blue-500"
              >
                Save
              </button>
            ) : (
              <button
                onClick={() => setIsEditing(true)}
                className="px-3 py-1 text-xs text-white bg-orange-500 rounded hover:bg-gray-500"
              >
                Edit
              </button>
            )}
          </li>
          <li>
            ID :{" "}
            <a className="text-blue-500" download href={data.ID}>
              Download
            </a>
          </li>
        </ul>
      </div>
      <div className="text-purple-500 text-md mt-3 mb-6 font-light uppercase">
        Business Details
      </div>
      <div>
        <ul>
          <li>Business Name : {data.shop[0].shopName}</li>
          <li>City : {data.shop[0].city}</li>
          <li>GST : {data.shop[0].gstNumber}</li>
          <li>PIN : {data.shop[0].address}</li>
          <li>Country : {data.shop[0].country}</li>
          <li>Address : </li>
          <li>{data.shop[0].address}</li>
        </ul>
      </div>
      <div className="mt-10 flex justify-evenly">
        {!data.is_approved && (
          <button
            onClick={() => {
              ApproveDetails(true);
            }}
            type="button"
            className="py-3 px-4 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-500 focus:outline-none hover:ring-blue-300"
          >
            Approve
          </button>
        )}
        {!data.is_rejected && (
          <button
            type="button"
            onClick={() => {
              ApproveDetails(false);
            }}
            className="py-3 px-5 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-500 focus:outline-none hover:ring-blue-300"
          >
            Reject
          </button>
        )}
      </div>
    </div>
  );
}
